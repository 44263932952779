<template>
  <div class="card-container">
    <el-card class="card-content">
      <div class="card-title mb-2">
        <h1 class="text-3xl text-blue-900 font-bold">Cadastrar nova senha</h1>
      </div>
      <el-form
      ref="ruleFormPassword"
      :model="password"
      >
        <el-form-item>
          <inputBase
            @keypress.enter="submitEmail"
            @newValue="passwordNew"
            classContent="relative w-full px-3 py-1 text-gray-500 shadow outline-none"
            type="password"
            placeholder="Infome sua senha"
            :validation="ruleValidationOne && ruleValidationTwo && ruleValidationThree && ruleValidationFour"
          />
        </el-form-item>
        <el-form-item>
          <inputBase
            @keypress.enter="submitEmail"
            @newValue="passwordConf"
            classContent="relative w-full px-3 py-1 text-gray-500 shadow outline-none"
            type="password"
            placeholder="Confirme a senha"
            :validation="ruleValidationOne && ruleValidationTwo && ruleValidationThree && ruleValidationFour"
          />
        </el-form-item>
        <div class="my-2 flex flex-col text-sm">
          <span :class="password.new ? ruleValidationOne ? 'text-green-500' : 'text-red-500' : 'text-gray-500'">* mínimo de 8 caracteres</span>
          <span :class="password.new ? ruleValidationTwo ? 'text-green-500' : 'text-red-500' : 'text-gray-500'">* mín. de 1 dos caracteres especias: #$@+!/%&*?</span>
          <span :class="password.new ? ruleValidationThree ? 'text-green-500' : 'text-red-500' : 'text-gray-500'">* mínimo de uma letra maiúscula</span>
          <span :class="password.new ? ruleValidationFour ? 'text-green-500' : 'text-red-500' : 'text-gray-500'">* mínimo de uma letra minúscula</span>
          <span :class="password.new ? ruleValidationFive ? 'text-green-500' : 'text-red-500' : 'text-gray-500'">* mínimo de um número</span>
        </div>
        <el-form-item class="button-container">
          <el-button
            class="button"
            type="primary"
            :loading=loading
            :disabled="(!password.new || !password.confirm) && true"
            size="large"
            @click="submitEmail"
          >Salvar</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { ElNotification } from 'element-plus'
import ForgotPasswordService from '../services/forgot_password.service'
import InputBase from '../components/InputBase.vue'

export default {
  components: { InputBase },
  name: "ForgotPassword",
  data() {
    return {
      email: { value: null },
      password: {
        new: null,
        confirm: null
      },
      rules: 10,
      loading: false
    }
  },
  computed: {
    ruleValidationOne() {
      return this.password.new && this.password.new.length >= 8
    },
    ruleValidationTwo() {
      return /[#$@+!/%&*?]/.test(this.password.new)
    },
    ruleValidationThree() {
      return /[A-Z]/.test(this.password.new)
    },
    ruleValidationFour() {
      if(this.password.new) return /[a-z]/.test(this.password.new)
    },
    ruleValidationFive() {
      return /\d/.test(this.password.new)
    }
  },
  mounted() {
    this.forgotPasswordService = new ForgotPasswordService();
  },
  methods: {
    passwordNew(e) {
      this.password.new = e
    },
    passwordConf(e) {
      this.password.confirm = e
    },
    submitEmail() {
      this.loading = true
      if(this.password.new != this.password.confirm) {
        ElNotification({
          title: 'Erro!',
          message: `As senhas informadas estão divergentes!`,
          type: 'error',
          duration: 4000
        })
        this.loading = false
        return
      }
      if(!this.ruleValidationOne || !this.ruleValidationTwo || !this.ruleValidationThree || !this.ruleValidationFour || !this.ruleValidationFive) {
        ElNotification({
          title: 'Erro!',
          message: `Algum critério de segurança não foi atendido.`,
          type: 'error',
          duration: 4000
        })
        this.loading = false
        return
      }
      this.forgotPasswordService.put_reset_pwd(this.$route.params.id, this.$route.query.email, { password: this.password.new })
      .then((data) => {
        console.log(data)
        this.$router.push('/')
      })
      .catch((err) => {
        console.log(err.response)
        if(err.response.status !== 403) this.$router.push('/email')
      })
      .finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.card-container {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-content {
  max-width: 500px;
}
.card-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
}
.button-container {
  width: 100%;
  display: grid;
  flex-direction: column;
  justify-content: stretch;
}
.button {
  width: 100%;
  font-size: 1.2rem;
}
</style>