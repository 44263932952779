<template>
  <div class="w-full">
    <input
      v-model="data"
      :class="`${classContent} border rounded ${validation ? 'border-green-400' : data.length === 0 ? 'focus:border-blue-400' : 'border-red-400'}`"
      :type="typeUpdated"
      :placeholder="placeholder"
      autocomplete="off"
      size="large"
    />
    <i v-if="data.length === 0"></i>
    <i v-else-if="data && typeUpdated === 'password'" @click="toogleInput('text')" class="ri-eye-off-line cursor-pointer absolute right-2 top-1 text-gray-400"></i>
    <i v-else-if="data && typeUpdated === 'text'" @click="toogleInput('password')" class="ri-eye-line cursor-pointer absolute right-2 top-1 text-gray-400"></i>
  </div>
</template>

<script>
export default {
  name: 'InputBase',
  props: ['valeu', 'classContent', 'type', 'placeholder', 'validation'],
  data() {
    return {
      data: '',
      typeUpdated: null
    }
  },
  mounted() {
    this.typeUpdated = this.type
  },
  computed: {
    isType: {
      get: function() {
        return this.type
      },
      set: function(newValue) {
        this.typeUpdated = newValue
      }
    }
  },
  methods: {
    toogleInput(value) {
      this.isType = value
    }
  },
  watch: {
    data(newValue) {
      this.$emit('newValue', newValue)
    }
  }
}
</script>
